<template>
	<div>
	<div class="register-container">
		<h2>Mot de Passe oublié</h2>
		<form @submit.prevent="register">
		<div class="form-group">
			<label for="email">Adresse e-mail :</label>
			<input type="email" id="email" v-model="email" required>
		</div>
		<div class="form-group">
			<label for="password">Nouveau mot de passe :</label>
			<input type="password" id="password" v-model="password" required>
			<small class="hint">Le mot de passe doit contenir entre 8 et 30 caractères, comprenant au moins une lettre majuscule, une lettre minuscule et un chiffre.</small>
		</div>
		<div class="form-group">
			<label for="confirmPassword">Confirmation du nouveau mot de passe :</label>
			<input type="password" id="confirmPassword" v-model="password2" required>
		</div>
        <div class="liens">
		    <v-btn color="primary" dark @click.prevent="TestData()" > Valider </v-btn>
			<router-link to="/login">Retour page connexion</router-link>
        </div>
        <v-dialog v-model="dialog" persistent max-width="600px" >
            <v-card>
                <v-card-title>
                <span class="text-h5">Il ne reste qu'une étape...</span>
                </v-card-title>
                <v-card-text>
                    <span class="text-h7">Nous vous avons envoyé un code de vérification à {{ email }}  </span>
                <v-container>
                    <v-row>
                    <v-col cols="12" >
                        <v-text-field label="Entrez le code de vérification reçu par e-mail" required v-model="code_input"></v-text-field>
                    </v-col>
                    </v-row>
                </v-container>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false, TestCode()" >Valider le code</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
		</form>
  	</div>
	</div>
</template>
<script>
	export default {
		name: 'Forget-password',
	
		data(){
			return {
				password: '',
				password2: '',
				email: '',
				dialog: false,
				code_input: '',
				errors: [],
				EmailExistenceLength:'',
				CodeExistenceLength: '',
				CodeVerificationExistenceLength: '',
				date_code: '',
			}
		},

		computed: {
			
			color () {
				return ['error', 'warning', 'success'][Math.floor(this.progress / 40)]
			},
		},
		methods: {
			//1.1- Verifie que tous les champs sont remplis
			//1.2- Si oui, Verifie que les input sont au bon format
			//1.3- Verifie que le username, et l'email n'existent pas déjà dans la tables user_account
			//1.4- Verifie que le code de création existe et est valide
			//1.5- Si oui, envoie d'un code de vérification de l'email
			async TestData(){
                this.errors = [];
				if( !this.password || !this.email || !this.password2){
					this.errors.push(" please enter all fields")
					window.alert(this.errors)
					return
				}else{
					
					if(this.password !== this.password2){
						this.errors.push( " Invalid passwords")
					}
                    if(!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,30}$/.test(this.password)){
                        this.errors.push( "Format Invalide pour le mot de passe")
                    }
					if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)){
						this.errors.push( " email pas au bon format")
					}
                    const existenceChecked = await this.CheckEmailExistence()
					if(existenceChecked == true){
                        console.log('l\'email existe bien');
                    }else{
						this.errors.push( "l'utilisateur n'existe pas, vérifiez l'orthographe")
                    }
					if(this.errors.length > 0){
						window.alert(this.errors)	
					}else{
                        console.log('sayouuuux')
						this.SendEmailCodeVerification();
                        this.dialog = true;
					}
				}
				
			},
			//2.1- Check de l'existence du code de vérification dans la table code_email_verification
			//2.2- Si oui, Check de la validité temporelle
			//2.3- Si oui, Insertion des informations rentrées dans la table user_account (nouvel utilisateur)
			//2.4- Incrémentation de 1 au nombre d'utilisation du code de création
			async TestCode(){
                console.log('heyyyo')
				const checkCodeExistence = await this.CheckCodeEmailVerificationExistence();
				if(checkCodeExistence == true ){
                    let date_exp = this.date_code
                    date_exp = parseInt(date_exp) + 900000;
                    const date_actuelle = Date.now()
                    if(date_actuelle <= date_exp){
                        await this.updatePassword();
                        window.alert("Mot de Passe mis à jour")
                        return
                    }else{
                        window.alert('the code is outdated')
                        return
                    }
                }else{
                    window.alert("No verification code")
                    if(this.errors.length > 0){
                        window.alert('Oops... There seems to be an error, please try again.')
                    }
                    return
                }
			},
			//Requête de la 1.5 Envoie de l'email avec le code aléatoire généré + insertion du code dans la table code_email_verification
			async SendEmailCodeVerification(){
				const email = encodeURIComponent(this.email)
				const url= this.$api.getRESTApiUri() + `/email/code-verification/${email}`;
				return fetch(url)
				.then(res => res.text())
				.catch((error) => {
					console.log(error)
				});
			},
			//Requête de la 1.3
			async CheckEmailExistence(){
				const email = encodeURIComponent(this.email)
				const url = this.$api.getRESTApiUri() + `/check-email-existence/${email}`
				return fetch(url)
                .then(res => res.text())
                .then((result) => {
                    const data = JSON.parse(result);
                    console.log(data)
					if(data.length > 0){
                        return true;
                    }else{
                        return false;
                    }
				})
				.catch((error) => {
					console.log(error)
				});	
			},

			//Requête de la 2.1
			async CheckCodeEmailVerificationExistence(){
				const code = encodeURIComponent(this.code_input)
				const url = this.$api.getRESTApiUri() + `/check-code-verification-existence/${code}`
				
				return fetch(url)
                .then(res => res.text())
                .then((result) => {
                    const data = JSON.parse(result);
					console.log(data)
                    if(data.length > 0) {
					    this.date_code = data[0].date_now;
                        return true;
                    }else{
                        return false;
                    }
				})
				.catch((error) => {
					console.log(error)
				});	
				
			},
			//Requête de la 2.3
			async updatePassword(){
				const url= this.$api.getRESTApiUri() + `/update-password-user`;
				return fetch(url, {
						method: 'PUT',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							password: this.password,
							email: this.email,
						}),
					})
				// Converting to JSON
				.then(response => response.json())
				// Displaying results to console
				.then(json => console.log(json))
			},

		}
	}
</script>
<style scoped>
.register-container {
	margin-top: 50px;
	margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  
}

.register-container form {
  width: 500px;
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.register-container h2 {
  font-family: Roboto, Light;
  font-size: 36px;
  font-weight: 300;
  color: var(--bleu);
  margin-bottom: 20px;
}
.token-input{
	
}
.form-group {
  margin-bottom: 20px;
}

.register-container label {
  display: block;
  color: var(--gris);
  margin-bottom: 5px;
}

.register-container input[type="text"],
.register-container input[type="email"],
.register-container input[type="password"] {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--grisclair);
  border-radius: 5px;
}

.register-container button {
  background-color: var(--bleu);
  color: white !important;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.register-container button:hover {
  background-color: var(--turquoise);
}

.liens {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.liens a {
  color: var(--bleu);
  text-decoration: none;
}

.liens a:hover {
  font-weight: 500;
}

.hint {
    font-size: 12px;
    color: #888;
    margin-top: 5px;
    display: block;
}

</style>